
import { Vue, Component } from 'vue-property-decorator';
import { parseJwt } from '@/utils/stringUtils';

@Component({ })
export default class ExternalLoginInclude extends Vue {
    public loading: boolean = false;

    public token: string = "";
    public cpf: string = "";

    public externalUserId: string = "";
    public externalUserName: string = "";

    private async mounted() {

        this.loading = true;

        this.token = this.$route.query.token.toString();
        this.cpf = this.$route.query.cpf.toString();

        const decodedToken = parseJwt(this.token);
        this.externalUserId = decodedToken.nameid;
        this.externalUserName = decodedToken.given_name;

        localStorage.setItem("externalUserId", this.externalUserId);
        localStorage.setItem("externalUserName", this.externalUserName);

        try {
            await this.$store.dispatch('ExternalSignIn', { cpf: this.cpf.replace(/[.-]/gi, ''), token: this.token });
        }
        catch(error) {
            this.$pilotarAlert('Opss...', 'Sess�o expirada, realize novamente o login na aplica��o.', [
                {
                    title: 'Ok',
                    primary: true,
                    action: () => {
                        window.top?.parent.postMessage("invalidToken", process.env.VUE_APP_SITE_URL);
                    }
                },
            ]);
        }
        
        this.loading = false;

        this.$store.commit('navigate', { page: 'new-scheduling-category', transition: 'crossFade', arguments: { fromPainel: true } });

    }
}



import NotificationIcon from '@/modules/core/components/notificationIcon/NotificationIcon.vue';
import { Component, Vue } from 'vue-property-decorator';
import AuthResponse from '../../../models/AuthResponse';
import HomeSkeleton from './HomeSkeleton.vue';

const SECONDS_TO_RETRY: number = 3;

@Component({ components: { HomeSkeleton, NotificationIcon } })
class HomeSemCadastro extends Vue {

    public get user(): AuthResponse{
        return this.$store.getters['getUser'];
    }

    public loading: boolean = this.user != null && this.user.alunoSuperPratico == true;    

    public MAX_RETRY: number = 5;

    public errorOnLoadClasses: boolean = false;
    public regressiveCounter: number = SECONDS_TO_RETRY;
    public retryCounter: number = 0;
    public syncStatus: string = "none";
    
    private handleRegressiveCounter: number = 0;

    public get syncing(): boolean {
        return this.$store.getters['IsSyncing'];
    }
    
    public get notifications() {
        return this.$store.getters['getNotifications'];
    }
    
    public get possuiVinculoCFC() {
        return this.$store.getters['getPossuiVinculoCFC'];
    }
    public get possuiProcessoPratico() {
        return this.$store.getters['getPossuiProcessoPratico'];
    }
    public get totalAulas() {
        return this.$store.getters['getClassesLength'];
    }
    public get aulasParaAvaliar(){
        return ((this.$store.getters['getNonEvaluatedClasses']?.length || 0) as number).toString().padStart(2, '0');
    }

    public openNotifications() {
        this.$store.commit('navigate', { page: 'notificacoes', transition: 'toUp' });
    }

    public GoToSearch() {
        this.$store.commit('setHomeMenuIndex', { index: null });
        this.$store.commit('replace', { page: 'autoescolas', transition: 'toLeft' });
    }

    private loadClasses() {
        if(this.user != null) {
            if (this.user.alunoSuperPratico){
                this.loading = true;
                this.$store.dispatch('loadClasses')
                    .then(() => {
                        this.$store.commit('setPossuiVinculoCFC', true);
                        this.$store.commit('setPossuiProcessoPratico', true);
                        this.loading = false;
                    })
                    .finally(() => {
                        this.errorOnLoadClasses = false;
                    })
                    .catch((error) => {
                        if ((error as any).error === "Processo pr�tico n�o encontrado.") {
                            this.$store.commit('setHomeMenuIndex', null);
                            this.$store.commit('setPossuiVinculoCFC', true);
                            this.$store.commit('setPossuiProcessoPratico', false);
                            this.loading = false;
                        }
                        else {
                            this.$store.commit('setPossuiVinculoCFC', false);
                            this.$store.commit('setPossuiProcessoPratico', true);
                            this.handleRegressiveCounter = setInterval(() => {
                                this.regressiveCounter -= 1;
                                if (this.regressiveCounter === -1) {
                                    clearInterval(this.handleRegressiveCounter);
                                    this.retryCounter += 1;
                                    if (this.retryCounter <= this.MAX_RETRY) {
                                        this.loadClasses();
                                    }
                                    else {
                                        this.loading = false;
                                    }
                                    this.regressiveCounter = SECONDS_TO_RETRY;
                                }
                            }, 1000);
                            this.errorOnLoadClasses = true;
                        }
                    });                
            }
            else {
                this.loading = false;
            }
        }
        else {
            this.loading = false;
        }
    }

    public async fetchData() {
        if(this.user != null) {
            if(this.user.cfcId == 0) {
                this.loading = true;
                this.$store.dispatch('Sync', this.user)
                    .then(() => {
                        this.syncStatus = "ok";
                        this.loadClasses();       
                    })
                    .catch(() => {
                        this.syncStatus = "error";
                    });
            }
            else {
                this.loadClasses();                
            }
            //this.notifications = await NotificationsService.getUnreadBySession(); 
            await this.$store.dispatch('fetchNotifications');
        }
    }

    private async mounted() {    
        await this.fetchData();
    }
}

export default HomeSemCadastro;

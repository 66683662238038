
import { Component, Vue } from 'vue-property-decorator';
import AuthResponse from '@/models/AuthResponse';
import { VsIcon } from '@/components/controls/vs-icon';
import NotificationIcon from '@/modules/core/components/notificationIcon/NotificationIcon.vue';

@Component({ components: { VsIcon, NotificationIcon } })
class TabsPage extends Vue {

    public get user(): AuthResponse {
        return this.$store.getters['getUser'];
    }

    public openNotifications() {
        this.$store.commit('navigate', { page: 'notificacoes', transition: 'toUp' });
    }

    private mounted() {
        this.isAulas = this.$route.path.includes('aulas');
        if (this.$parent)
            this.$parent.$on('navigated', (page: string) => this.isAulas = page.includes('aulas'));
    }

    public isAulas: boolean = false;
}

export default TabsPage;


import UserReferenceDTO from '@/modules/auth/DTOs/UserReferenceDTO';
import UserAvatar from '@/modules/auth/components/userAvatar/UserAvatar.vue';
import EventDTO from '@/modules/scheduling/DTOs/events/EventDTO';
import SchedulingSelectedItems from '@/modules/scheduling/views/SchedulingSelectedItems.vue';
import { MONTHS } from '@/utils/dateUtils';
import { format } from 'date-fns';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { SchedulingSelectedItems, UserAvatar } })
class AulasAgendadasCard extends Vue {
    
    @Prop({ type: (Object as () => EventDTO), required: true })
    public event!: EventDTO;

    public get start(): Date | null {
        if (this.event?.start)
            return new Date(this.event.start);
        return null;
    }

    public get end(): Date | null {
        if (this.event?.start)
            return new Date(this.event.end);
        return null;
    }

    public get minutesRange(): number | null {
        if (this.start && this.end)
            return parseInt((((this.end.getTime() - this.start.getTime()) / 1000) / 60).toString(), 10);
        return null;
    }

    public get teacherUser(): UserReferenceDTO | undefined {
        return this.event.participants.find(participant => participant.labels.includes('instructor'))?.user;
    }

    public get date(): string {
        if (this.start)
            return `${this.start.getDate() < 10 ? '0' : ''}${this.start.getDate()} / ${MONTHS[this.start.getMonth()]} / ${this.start.getFullYear()}`;
        return "";
    }

    public get startHour(): string {
        if (this.start)
            return format(this.start, 'HH:mm');
        return "";
    }

    public get endHour(): string {
        if (this.end)
            return format(this.end, 'HH:mm');
        return "";
    }

    public click() {
        this.$emit('onPressed');
    }

}

export default AulasAgendadasCard;


import { Component, Vue } from 'vue-property-decorator';
import { MglMap, MglMarker } from "vue-mapbox";
import ResponseCfcDTO from '../../models/ResponseCfcDTO';

@Component({ components: { MglMap, MglMarker } })
class Map extends Vue {

    private showModalGPS: boolean = false;
    private isGpsOk: boolean = false;

    private async mounted(): Promise<void> {
        try {
            const resp = await this.$store.dispatch('LoadCurrentPosition');
            if (resp != null) {
                this.center = this.$route.name == 'buscarmapa'? this.$store.getters['getMapPosition'] : [this.selectedLocation.longitude, this.selectedLocation.latitude];
                this.isGpsOk = true;
            }
            else {
                this.center = [-34.861, -7.11532];
                this.$store.commit('setPositionFromMap', false);
                this.$store.commit('setCurrentPosition', null);
                this.$store.commit('setBlockedSearchOnLoad', true);
                this.isGpsOk = false;
            }
        }
        catch(error) {
            this.$store.commit('setPositionFromMap', false);
            this.$store.commit('setCurrentPosition', null);
            this.$store.commit('setBlockedSearchOnLoad', true);
            this.isGpsOk = false;
        }

        finally{
            setTimeout(() => {
                this.loaded = true;
            }, 500);
        }
    }

    private loaded: boolean = false;
    private showPopUp: boolean = false;
    private moving: boolean = false;
    private center: [number, number] = [0,0];

    private centerChange(e: { lng: number; lat: number}){
        this.center = [e.lng, e.lat];
    }

    private closeModal() {
        this.showModalGPS = false;
        this.$store.commit('goBack');
    }

    private carregouMapa() {
        if (!this.isGpsOk) {
            this.showModalGPS = true;

        }
        else {
            setTimeout(() => {
                this.showPopUp = true;
            }, 300);
        }
    }

    private confirmar(): void {
        this.$store.commit('setUserType', 'naoAluno');
        this.$store.commit('setPositionFromMap', true);
        this.$store.commit('setCurrentPosition', this.$route.name == 'buscarmapa'? this.center : [this.selectedLocation.longitude, this.selectedLocation.latitude]);
        this.$store.commit('navigate', { page: 'autoescolas', 'transition': 'crossFade' });
    }

    private get selectedLocation(): ResponseCfcDTO {
        return this.$store.getters['GetSelectedLocation'];
    }
}

export default Map;


import { Component, Vue } from 'vue-property-decorator';

@Component({  })
class Onboarding extends Vue {

    public navigate(): void {
        const page = (
            this.$route.name == 'onboardingbemvindo'
                ? 'onboardingsimulados' 
                : this.$route.name == 'onboardingsimulados' 
                    ? 'onboardingaprendamais' 
                    : 'simulados'
        );
        this.$store.commit('replace', { page: page, transition: 'toLeft' });
    }
}

export default Onboarding;


import { Component, Vue } from 'vue-property-decorator';
import AulasConcluidasCard from './AulasConcluidasCard.vue';

@Component({ components: { AulasConcluidasCard } })
class AulasConcluidas extends Vue {

    public showBallon: boolean = false;

    public get classes(){
        return this.$store.getters['getNonEvaluatedClasses'];
    }

    public get loading(){
        return this.$store.getters['loadingClasses'];
    }

    public selectClass(oid: string, idAula: string){
        this.$store.commit('navigate', { page: 'auladetalhe', params: { id: oid }, query: { c: 'DetalhePercurso', idAula }, transition: 'toUp' });
    }

    private mounted(){
        setTimeout(() => {
            if(localStorage.getItem('balloon#pilotarapp') == null) {
                this.showBallon = true;
                localStorage.setItem('balloon#pilotarapp', 'true');
            }
        }, 1000);
    }    
}

export default AulasConcluidas;


import { Component, Vue } from 'vue-property-decorator';
import AlertBalloon from './AlertBalloon.vue';
import ErrorView from '@/views/ErrorView.vue';
import ResourceDTO from '@/modules/scheduling/DTOs/resources/ResourceDTO';
import ResourcesService from '@/modules/scheduling/services/ResourcesService';

@Component({ components: { AlertBalloon, ErrorView } })
class MinhasAulasPage extends Vue {

    public resourceCompany: ResourceDTO | null = null;

    private beforeMount(){
        if(this.$store.getters['getClassesLength'] == 0) {
            this.loadClasses();
        }
    }

    public newScheduling(){
        this.$store.commit('navigate', { page: 'new-scheduling-category', transition: 'toUp' });
    }

    private async mounted(){
        setTimeout(() => {
            if(localStorage.getItem('balloon#pilotarapp') == null) {
                this.showBallon = true;
                localStorage.setItem('balloon#pilotarapp', 'true');
                setTimeout(() => {
                    this.showBallon = false;
                }, 5000);
            }
        }, 1000);

        await this.fetchData();
    }

    private async fetchData() {

        try {
            this.resourceCompany = await ResourcesService.getBySession();
        }
        catch(error) {
            this.$pilotarAlert('Opss...', 'Erro ao conectar no servidor, tente novamente em alguns instantes', [
                {
                    title: "Tentar novamente",
                    primary: true
                }
            ]);
        }
    }    

    beforeDestroy() {
        this.showHeader = false;
    }

    public loadClasses(){
        this.error = '';
        this.$store.dispatch('loadClasses')
            .catch(error => this.error = error);
    }
    
    public error: string = '';
    public showHeader: boolean = true;
    public showBallon: boolean = false;
}

export default MinhasAulasPage;


import { Component, Vue } from 'vue-property-decorator';

@Component({ })
class NaoCadastrado extends Vue {
    private pular(){
        this.$store.commit('setUserType', 'alunoNaoRegistrado');
        this.$store.commit('navigate', { page: 'home', transition: 'toLeft' });
    }
}

export default NaoCadastrado;


import { Component, Vue } from 'vue-property-decorator';
import NavigationBarPage from '@/components/pages/NavigationBarPage/NavigationBarPage.vue';
import NavigationBarItem from '@/components/pages/NavigationBarPage/NavigationBarItem.vue';
import AuthResponse from '@/models/AuthResponse';

@Component({ components: { NavigationBarItem, NavigationBarPage } })
class HomeMainPage extends Vue {
    public get user(): AuthResponse{
        return this.$store.getters['getUser'];
    }

    private get loadingClasses() {
        return this.$store.getters['loadingClasses'];
    }

}

export default HomeMainPage;


import { Component, Vue, Watch } from 'vue-property-decorator';
import  VsLoadingModal from '@/components/layouts/vs-LoadingModal.vue';
import ResponseCfcDTO from '../../models/ResponseCfcDTO';
import LocationService from '@/services/LocationService';
import { Canceler } from 'axios';

@Component({ components: { VsLoadingModal } })
class PesquisarLocalizacao extends Vue {

    private pesquisa: string = '';
    private loading: boolean = false;
    private firstLoad: boolean = false;
    private inputTimeout: number = -1;
    private loadingPosition: boolean = false;
    private beforeMountExecuted: boolean = false;
    private canceler: Canceler | null = null;

    // =============== M�todos =================
    private focus(): void {
        if(this.$route.name == 'localizacao') {
            this.$store.commit('navigate', { page: 'pesquisarlocalizacao', transition: 'toLeft' });
        }
    }

    private get inFocus(){
        return this.$route.name == 'pesquisarlocalizacao';
    }

    private get position(): [number, number] | null {
        return this.$store.getters['getCurrentPosition'];
    }

    private beforeMount(){
        if (localStorage.getItem("getlocationallowed#pilotarapp") != null && localStorage.getItem("getlocationallowed#pilotarapp") === "true") {
            this.usarLocalizacao();
            this.beforeMountExecuted = true;
        }
        else {
            this.beforeMountExecuted = true;
        }
    }

    @Watch('inFocus')
    onFocusChange(newValue: boolean) {
        if(!newValue) {
            this.pesquisa = '';
            this.locations = [];
            this.firstLoad = false;
            this.canceler && this.canceler();
            clearTimeout(this.inputTimeout);
        }
    }

    // Deixar bold texto da busca
    private boldString(text: string): string {
        const textoSemAcento = this.$store.getters['removerAcento'](text.toLowerCase());
        const pesquisaSemAcento = this.$store.getters['removerAcento'](this.pesquisa.toLowerCase());

        const hasWord = textoSemAcento.search(new RegExp(pesquisaSemAcento, 'gi'));
        if(hasWord >= 0 && this.pesquisa.length > 0){
            return text.substr(0, hasWord) + '<b>' + text.substr(hasWord, this.pesquisa.length) + '</b>' + text.substring(hasWord + this.pesquisa.length, text.length);
        }
        else {
            return text;
        }
    }

    // Clicar no bot�o de "N�o achei meu endere�o"
    private pesquisarLocalizacao(): void {
        this.loadingPosition = true;
        this.$store.dispatch('LoadCurrentPosition')
            .then(resp => this.$store.commit('SetMapPosition', resp))
            .finally(() => {
                this.loadingPosition = false;
                this.$store.commit('replace', { page:'buscarmapa', transition: 'toLeft' });
            });
    }

    // Clicar no bot�o de usar minha localiza��o
    private usarLocalizacao(){
        this.loadingPosition = true;
        this.$store.dispatch('LoadCurrentPosition')
            .then((resp) => {
                this.$store.commit('setUserType', 'naoAluno');
                this.$store.commit('setCurrentPosition', resp);
                this.$store.commit('navigate', { page: 'autoescolas', transition: 'crossFade' });
            })
            .catch((error) => {
                this.$pilotarAlert('Opss...', error);
            })
            .finally(() => {
                this.loadingPosition = false;
            });
    }

    // Selecionar endere�o
    private selectAddress(location: ResponseCfcDTO): void {
        this.$store.commit('SetSelectedLocation', location);
        this.$store.commit('navigate', { page: 'onboardingmap', transition: 'toLeft' });
    }

    private buscarEnderecos(){
        return new Promise((resolve) => {
            const position = this.position != null ? { lat: this.position[1], lng: this.position[0] } : null;
            const [request, canceler] = LocationService.SearchAddress(this.pesquisa, position);
            this.canceler = canceler;
            request
                .then(resp => {
                    this.locations = resp;
                })
                .finally(() => resolve());
        });
    }

    // ============ Observadores ==============
    @Watch('pesquisa')
    private onSearchChange(): void {
        clearTimeout(this.inputTimeout);
        this.canceler && this.canceler();

        (this.inputTimeout as any) = setTimeout(() => {
            if(this.pesquisa.length) {
                this.loading = true;
                this.buscarEnderecos()
                    .then(() => this.firstLoad = true)
                    .catch(error => console.log(error))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }, 1200);
    }

    // ============ Vari�veis computadas =============
    private locations: ResponseCfcDTO[] = [];
}

export default PesquisarLocalizacao;

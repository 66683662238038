
import AppSchedulingService from '@/app/services/AppSchedulingService';
import EventDTO from '@/modules/scheduling/DTOs/events/EventDTO';
import { Component, Vue } from 'vue-property-decorator';
import AulasAgendadasCard from './AulasAgendadasCard.vue';
import ResourceDTO from '@/modules/scheduling/DTOs/resources/ResourceDTO';

@Component({ components: { AulasAgendadasCard } })
export default class AulasAgendadas extends Vue {

    public loading: boolean = true;
    public events: EventDTO[] = [];
    public resourceCompany: ResourceDTO | null = null;
    public categoria: string = 'proximas';

    public get filteredEvents(): EventDTO[] {
        return this.events.filter(e => this.categoria == 'proximas' ? new Date(e.end) >= new Date() : new Date(e.end) < new Date());
    }

    private async fetchData() {
        this.loading = true;

        try {
            const events: EventDTO[] | null = await AppSchedulingService.getEventsBySession();

            if (events != null) {
                events.sort((a, b) => a.start > b.start ? 1 : -1);
                this.events = events;
            }
        }
        catch(error) {
            this.$pilotarAlert('Opss...', 'Erro ao conectar no servidor, tente novamente em alguns instantes', [
                {
                    title: "Tentar novamente",
                    primary: true
                }
            ]);
        }
        finally {
            this.loading = false;
        }
    }

    public navigate(page: string) {
        this.$emit("edit", page);
    }    

    private async mounted() {
        await this.fetchData();
    }

    public onPressed(event: EventDTO): void {
        this.$store.commit('navigate', { page: 'scheduling-details', transition: 'toUp', params: { id: event.id } });
    }

}

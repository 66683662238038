
import { Component, Vue, Prop } from 'vue-property-decorator';
interface Percurso  {
    id: string;
    descricao: string;
    icone: string;
}

@Component({ })
class CategoriaListItem extends Vue {

    

    @Prop({ type: Object, required: true })
    private percurso!: Percurso;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private onClick() {
        this.$emit('click', this.percurso.id);
    }
}

export default CategoriaListItem;

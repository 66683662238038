
import { Component, Vue, Prop } from 'vue-property-decorator';
import AulaDTO from '../../../models/AulaDTO';
import moment from 'moment';
@Component({})
class AulasConcluidasCard extends Vue {
    
    @Prop({ type: Object, required: true })
    public aula!: AulaDTO;

    public get date(){
        return moment(this.aula.inicio, 'DD-MM-YYYY HH:mm:ss');
    }

    public get inicio(){
        return this.date.format('DD / MMMM / YYYY');
    }

    public get hora(){
        return this.date.format('HH:mm');
    }

    public capitalCase(name: string) {
        return name.split(' ').map(el => el.charAt(0).toUpperCase() + el.slice(1).toLowerCase()).join(' ');
    }

    public tipoSituacao(value: number) {
        switch (value) {
            case 0:
                return 'Aguardando libera��o';
            case 1:
                return 'Avaliar aula';
            case 2:
                return 'Avaliar aula';
            case 3:
                return 'Recusada';
            case 4:
                return 'Em execu��o';
            case 5:
                return 'Processamento pendente';
            default:
                break;
        }
    }

}

export default AulasConcluidasCard;


import NativeService from '@/services/NativeService';
import { Component, Vue } from 'vue-property-decorator';

@Component({ })
class StartPage extends Vue {
    private mounted(){
        const userType = localStorage.getItem('usertype#pilotarapp') as 'alunoRegistrado' | 'alunoNaoRegistrado' | 'naoAluno';
        const coordinates = localStorage.getItem('coordinates#pilotarapp');

        if(userType == 'naoAluno' && coordinates != null) {
            NativeService.GetCurrentPosition()
                .then(resp => this.$store.commit('setCurrentPosition', resp))
                .catch(() =>  this.$store.commit('setCurrentPosition', JSON.parse(coordinates)))
                .finally(() => this.$store.commit('replace', { page: 'simulados', transition: 'crossFade' }));
        }
        else if(userType == 'alunoRegistrado' || userType == 'alunoNaoRegistrado') {
            this.$store.commit('replace', { page: 'home', transition: 'crossFade' });
        }
        else {
            this.$store.commit('replace', { page: 'onboardingbemvindo', transition: 'crossFade' });
        }
    }
}

export default StartPage;

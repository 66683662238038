
import { trackers } from '@/plugins/insights';
import { Component, Vue } from 'vue-property-decorator';
import CategoriaListItem from './CategoriaListItem.vue';

@Component({ components: { CategoriaListItem } })
class CategoriaDetalhe extends Vue {

    private categoria = 'aulas';

    private get percursos() {
        if(this.$route.query.categoria == 'a'){
            return [
                {
                    id: this.categoria == 'aulas' ? 'MotoConePack' : 'MotoConeExamePack',
                    descricao: 'Manobra entre os cones',
                    icone: 'aulas-cones'
                },
                {
                    id: this.categoria == 'aulas' ? 'MotoOitoPack' : 'MotoOitoExamePack',
                    descricao: 'Manobra em formato de oito',
                    icone: 'aulas-oito'
                },
                {
                    id: this.categoria == 'aulas' ? 'MotoPranchaPack' : 'MotoPranchaExamePack',
                    descricao: 'Passagem sobre a prancha',
                    icone: 'aulas-prancha'
                },
                {
                    id: this.categoria == 'aulas' ? 'MotoCompletoPack' : 'MotoCompletoExamePack',
                    descricao: 'Circuito completo',
                    icone: 'aulas-completo'
                },
                {
                    id: 'MotoZonaUrbanaPack',
                    descricao: 'Zona urbana',
                    icone: 'aulas-urbana'
                }
                
            ];
        }
        else {
            return [
                {
                    id: this.categoria == 'aulas' ? 'BalizaDireitaPack' : 'BalizaDireitaExamePack',
                    descricao: 'Baliza � direita',
                    icone: 'aulas-baliza-d'
                },
                {
                    id: this.categoria == 'aulas' ? 'BalizaEsquerdaPack' : 'BalizaEsquerdaExamePack',
                    descricao: 'Baliza � esquerda',
                    icone: 'aulas-baliza-e'
                },
                {
                    id: this.categoria == 'aulas' ? 'GaragemFrentePack' : 'GaragemFrenteExamePack',
                    descricao: 'Garagem de frente',
                    icone: 'aulas-frente'
                },
                {
                    id: this.categoria == 'aulas' ? 'GaragemRePack' : 'GaragemReExamePack',
                    descricao: 'Garagem de r�',
                    icone: 'aulas-re'
                },
                {
                    id: this.categoria == 'aulas' ? 'EmbreagemPack' : 'EmbreagemExamePack',
                    descricao: 'Meia embreagem',
                    icone: 'aulas-embreagem'
                },
                {
                    id: this.categoria == 'aulas' ? 'CarroCompletoPack' : 'CarroCompletoExamePack',
                    descricao: 'Circuito completo',
                    icone: 'aulas-completo'
                },
                {
                    id: 'ZonaUrbanaPack',
                    descricao: 'Zona urbana',
                    icone: 'aulas-urbana'
                },
            ];
        }
    }

    private percursoSelecionado(idPercurso: string, disabled: boolean) {
        if(!disabled) {
            // AppInsights
            trackers.registerStartedPracticalTest(idPercurso);
            this.$emit('loadgame', idPercurso);
        }
        else {
            this.$pilotarAlert("Opss...", "Para acessar este percurso voc� precisa ser um aluno de uma autoescola Premium Pilotar");
            // AppInsights
            trackers.registerUnavaiablePracticalTest(idPercurso);
        }
    }
}

export default CategoriaDetalhe;


import { Component, Vue } from 'vue-property-decorator';

import { ValidationObserver } from 'vee-validate';

@Component({ components: { ValidationObserver } })
class RecuperarSenha extends Vue {

    private email: string = '';
    private submited: boolean = false;
    private loading: boolean = false;

    private submit(): void {
       
        const observer = this.$refs.form as InstanceType<typeof ValidationObserver>;
        observer.$emit('validate');
    
        observer.validate()
            .then((isValid) => {
                if(isValid) {
                    this.loading = true;
                    this.$store.dispatch('RedefinePassword', this.email)
                        .then(() => this.submited = true)
                        .catch(error => this.$pilotarAlert('Opss...', error))
                        .finally(() => this.loading = false);
                }
            });
    }

}

export default RecuperarSenha;

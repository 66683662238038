
import { Component, Vue } from 'vue-property-decorator';

@Component({ })
class SimuladosPage extends Vue {

    private simuladoPratico(){
        if(this.$store.getters['userRegistered']) {
            this.$store.commit('navigate', { page: 'simuladopratico', transition: 'toUp' });
        }
        else {
            this.$store.commit('navigate', { page: 'onboarding-pratico-1', transition: 'crossFade' });
        }
    }
    private resultados(){
        if(this.$store.getters['userRegistered']) {
            this.$store.commit('navigate', { page: 'disciplinas', transition: 'toUp' });
        }
        else {
            this.$store.commit('navigate', { page: 'onboardingaulas', transition: 'crossFade' });
        }
    }
}

export default SimuladosPage;

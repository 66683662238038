
import { Component, Vue, Prop } from 'vue-property-decorator';
import AulaDTO from '../../../models/AulaDTO';
import moment from 'moment';

@Component({ filters: {
    capitalCase(name: string) {
        return name.split(' ').map(el => el.charAt(0).toUpperCase() + el.slice(1).toLowerCase()).join(' ');
    }
} })
class AulasAvaliadasCard extends Vue {

    @Prop({ type: Object, required: true })
    private aula!: AulaDTO;

    private get date(){
        return moment(this.aula.inicio, 'DD-MM-YYYY HH:mm:ss');
    }

    private get inicio(){
        return this.date.format('DD / MMMM / YYYY');
    }

    private get hora(){
        return this.date.format('HH:mm');
    }
}

export default AulasAvaliadasCard;

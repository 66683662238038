
import { Component, Vue } from 'vue-property-decorator';
import NativeService from '@/services/NativeService';

@Component({})
class SimuladoPraticoMainpage extends Vue {

    mounted(){
        window.addEventListener('resize', this.resize);
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    }

    private resize() {
        setTimeout(() => {
            this.$root.$emit('updateSize');
        }, 500);
    }

    private get contentTop() {
        const size = this.$route.name == 'simuladopratico' ? 260 : 55;
        return size + 'px';
    }

    private loadingGame: boolean = false;
    private count: number = 0;

    private loadgame(id: string){
        this.loadingGame = true;
        setTimeout(() => {
            NativeService.OpenGame(id);
            setTimeout(() => {
                this.loadingGame = false;
            }, 1000);
        }, 1000);
    }
}

export default SimuladoPraticoMainpage;

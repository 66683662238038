
import { Component, Vue } from 'vue-property-decorator';
import AulasAvaliadasCard from './AulasAvaliadasCard.vue';

@Component({ components: { AulasAvaliadasCard } })
class AulasAvaliadas extends Vue {

    private get classes(){
        return this.$store.getters['getEvaluatedClasses'];
    }

    private get loading(){
        return this.$store.getters['loadingClasses'];
    }

    private selectClass(oid: string, idAula: string){
        this.$store.commit('navigate', { page: 'auladetalhe', params: { id: oid }, query: { c: 'DetalhePercurso', idAula }, transition: 'toUp' });
    }
}

export default AulasAvaliadas;

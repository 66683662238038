
import { Component, Vue } from 'vue-property-decorator';

@Component({})
class TipoUsuario extends Vue {
    private inFocus: boolean = false;
    private page: string = 'tipoUsuario';

    private swipeRight() {
        if(this.$route.name == 'tipousuario') {
            this.$store.commit('replace', { page: 'onboardingaprendamais', transition: 'toRight' });
        }
    }
}

export default TipoUsuario;

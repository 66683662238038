
import { Component, Vue } from 'vue-property-decorator';

@Component({})
class OnboardingPraticoMainPage extends Vue {
    private mounted(){
        this.actualPage = this.$route.meta!.index;
    }
    private actualPage: number = 0;
    private transition: string = '';
    private navigate(index: number) {
        const to = this.actualPage + index;
        if(to >= 1 && to <= 4) {
            this.transition = to > this.actualPage ? 'toLeft' : 'toRight';

            this.$router.replace({ name: 'onboarding-pratico-' + to });
            this.actualPage = to;
        }
    }
}

export default OnboardingPraticoMainPage;

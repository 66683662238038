
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import { ValidationObserver } from 'vee-validate';
import NativeService from '../../services/NativeService';
import CameraOptions from '../../enums/CameraOptionsEnum';

@Component({ components: { ValidationObserver }, directives: { mask } })
class LoginInclude extends Vue {
    public usuario: string = '';
    public senha: string = '';
    public base64Image: string = '';
    public base64ImageSrc: string = `data:image/png;base64, ${this.base64Image}`;
    public passwordEnabled: boolean = false;
    public base64ImageEnabled: boolean = this.$route.query.debug == "true";
    public loading: boolean = false;
    public appVersion: string = this.$store.getters["GetAppVersion"].toString().replace(/[+]/gi, '.');

    public async submit() {
        const observer = this.$refs.form as InstanceType<typeof ValidationObserver>;
        observer.validate()
            .then(async (isValid) => {                
                if(isValid) {
                    this.loading = true;
                    // VALIDAR SENHA
                    if(this.passwordEnabled) {
                        await this.signIn();
                    }
                    else {
                        this.cpfSignIn();
                    }
                }
            });
    }

    public async signIn(){
        await this.$store.dispatch('SignIn', { emailOuCpf: this.usuario, chave: this.senha })
            .then(() => {
                this.$store.commit('clearStack', { page: 'home', transition: 'crossFade' });
            })
            .catch(error => this.$pilotarAlert('Opss...', error))
            .finally(() => this.loading = false);
    }

    public cpfSignIn(){
        if (!this.base64ImageEnabled){
            NativeService.TakePicture(CameraOptions.Front)
                .then((resp) =>this.$store.dispatch('SignIn', { emailOuCpf: this.usuario.replace(new RegExp('[.-]', 'gi'), ''), chave: resp }))
                .then(() => this.$store.commit('navigate', { page: 'home', transition: 'crossFade' }))
                .catch(() => {
                    this.$pilotarAlert('Opss...', "Usu�rio ou imagem inv�lidos. Experimente mudar de posi��o, buscar um lugar com mais ilumina��o e tente novamente.");
                })
                .finally(() => {
                    this.loading = false;
                });
        }
        else{
            (async ()=>{
                try{
                    await this.$store.dispatch('SignIn', { emailOuCpf: this.usuario.replace(new RegExp('[.-]', 'gi'), ''), chave: this.base64Image });
                    this.$store.commit('navigate', { page: 'home', transition: 'crossFade' });
                }
                catch(error){
                    this.$pilotarAlert('Opss...', "Usu�rio ou imagem inv�lidos. Experimente mudar de posi��o, buscar um lugar com mais ilumina��o e tente novamente.");
                }
                finally{
                    this.loading = false;
                }
            })();
        }
                
    }

    public get isCpf(){
        return (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/).test(this.usuario);
    }

    public verifyUser(): Promise<void>{
        return new Promise((resolve, reject) => {
            const data = this.isCpf ? this.usuario.replace(new RegExp('[.-]', 'gi'), '') : this.usuario;
            this.$store.dispatch('VerifyUser', this.isCpf ? { cpf:  data } : { email: this.usuario })
                .then(resp => resp.length > 0 ? resolve() : reject(true))
                .catch(() => {
                    this.$pilotarAlert('Opss...', 'N�o conseguimos nos comunicar com nosso servidor, por favor, tente novamente');
                    reject(false);
                });
        });
    }

    public async onPasteImage(): Promise<void> {

        const clip: any = navigator.clipboard;

        const clipboardData = await clip.read();
        const clipboardItem = clipboardData[0];

        const allowedTypes: any = {
            'image/gif': true,
            'image/png': true,
            'image/jpeg': true
        };

        for (const item of clipboardItem.types){
            if (allowedTypes[item]) {
                const reader = new FileReader();
                reader.onload = (event: any)=>{
                    this.base64Image = event.target.result.toString().replace("data:image/png;base64,", "");
                };

                const blob = await clipboardData[0].getType("image/png");
                reader.readAsDataURL(blob);
            }
        }
    }

    public async onPasteText(): Promise<void> {
        const clipboardData = await navigator.clipboard.readText();
        this.base64Image = clipboardData.toString();
    }

    @Watch('base64Image')
    onBase64ImageChange(newValue: number) {
        this.base64ImageSrc = `data:image/png;base64, ${newValue}`;
    }    
}

export default LoginInclude;
